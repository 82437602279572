import { Outlet } from '@remix-run/react';
import { Provider as ReduxProvider } from 'react-redux';

import { useUserAnalytics } from '../analytics/user';
import { AdminAccess } from '../components/Access';
import { UserAccess } from '../components/Access/UserAccess';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider } from '../components/UserContext';
import { store } from '../store/configureStore';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export function Component() {
  const providers = [
    <ReduxProvider store={store} children={[]} />,
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <ConfirmCancelModalProvider />,
    <UserAccess />,
    <AdminAccess />,
  ];

  return (
    <ProvidersList providers={providers}>
      <Outlet />
      <ConfirmCancelModalRoot />
    </ProvidersList>
  );
}
